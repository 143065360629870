@use '@angular/material' as mat;

@include mat.core();

@import "assets/theme/materialPalette";

$my-primary: mat.define-palette($primary-palette);
$my-accent: mat.define-palette($secondary-palette);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    theme-type: light
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($my-theme);

/* You can add global styles to this file, and also import other style files */
@import "assets/theme/variables.css";
@import "assets/theme/elements.css";
@import "assets/theme/layout.css";
@import "assets/theme/animations.css";
@import "assets/theme/materialElements.scss";
@import "assets/theme/registrierung.scss";
//@import "@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/material-icons/material-icons.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-style: normal;
  font-size: 14px;
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

/**###########################################################################################**/
/*HTML Elemente*/
:root{
  --schrift: #34495e;
  --mdc-snackbar-container-color: var(--primary);
}

h1{
  font-family: Roboto;
  font-weight: 400;
  line-height: 32px;
}

h2{
  font-family: Roboto;
  font-weight: 500;
  line-height: 32px;
}

h3{
  font-family: Roboto;
  font-weight: 600;
  line-height: 32px;
}

h4{
  font-family: Roboto;
  font-weight: 700;
  line-height: 32px;
}

h5{
  font-family: Roboto;
  font-weight: 800;
  line-height: 32px;
}

h6{
  font-family: Roboto;
  font-weight: 900;
  line-height: 32px;
}

div{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

strong{
  font-family: Roboto-Bold !important;
  font-size: 14px !important;
  color: var(--primary);
  font-weight: normal;
}

span{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
  letter-spacing: normal;
}

p{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

ul{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

li{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

a{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--primary);
}

label{
  font-family: Roboto !important;
  font-size: 14px !important;
  color: var(--schrift);
}

input{
  font-family: Roboto !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
  color: #34495e;
}

button{
  font-family: Roboto !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-height: 30px;
  border: none;
}

button span{
  color: #fff;
}

textarea{
  font-family: Roboto !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
}

/**###########################################################################################**/
/* Überbrückung für Contentblöcke */

.contentblock strong{
  font-size: unset !important;
  color: #34495e;
}

/**###########################################################################################**/
/* Scrollbar */

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-thumb{
  padding: 5px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 10px;
}

::-webkit-scrollbar-button:vertical:increment {
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 3px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  background-color: var(--primary);
  -webkit-border-radius: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 50px;
  background-color: var(--primary);
  -webkit-border-radius: 3px;
}

/**###########################################################################################**/
/** HTML Globale Elemente **/

registrierung-info-seitenleiste{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

html, body {
  height: calc(100% + env(safe-area-inset-top));
  overscroll-behavior: none !important;
}
body {
  margin: 0;
  color: #293133;
  background-color: #fff;
  overflow: hidden !important;
  overscroll-behavior: none !important;
  hyphens: auto;
}

.special-date span{
  color: var(--secondary) !important;
  font-weight: bold;
}

.zuruecksetzenTooltip{
  background-color: var(--priweak);
  color: #fff !important;
  margin: 50px 0 0 0 !important;
}

.app-content{
  height: calc(100% - 50px);
}

app-registrierung-info, app-registrierung-formular, app-registrierung-erfolgreich, app-registrierung-zusammenfassung{
  /*height: 100%;*/
  /*display: block;*/
  /*overflow-y: scroll;*/
  /*scrollbar-width: none;*/
}

/** Bestelltabellen **/
.menu-bezeichnung { grid-area: bezeichnung; }
.menu-einzelpreis { grid-area: einzelpreis; text-align: center; }
.menu-menge { grid-area: menge; text-align: center; }
.menu-update { grid-area: update; text-align: center; }
.menu-gesamtpreis { grid-area: gesamtpreis; text-align: center; }
.menu-beschreibung { grid-area: content; }
.menu-fehler { grid-area: fehler; }

.bestellung-kopf{
  display: grid;
  grid-template-areas:
  'bezeichnung update einzelpreis gesamtpreis';
  grid-template-columns: calc(100% - 195px) 60px 60px 60px;
  grid-gap: 5px;
}

.bestellung-zeile{
  display: grid;
  grid-template-areas: 'bezeichnung update einzelpreis gesamtpreis';
  grid-template-columns: calc(100% - 195px) 60px 60px 60px;
  grid-gap: 5px;
  padding: 5px 0 5px 0;
}

.warenkorb-kopf{
  display: grid;
  grid-template-areas:
  'bezeichnung menge update einzelpreis gesamtpreis';
  grid-template-columns: calc(100% - 250px) 60px 60px 60px 60px;
  grid-gap: 5px;
}

.speiseplanLeer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  margin: 10px 0 0 0;
}

.splanTag{
  background-color: white;
  border-radius: 0 10px 10px 10px;
  padding: 5px;
}

.horizontal1{
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.horizontal2{
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.horizontal3{
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-row: blue;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.zeilenUmbruch{
  grid-row: 2;
  margin: 30px;
}

.horizontal3::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: red; /* Hier kannst du die Hintergrundfarbe anpassen */
  z-index: -1;
  width: calc(100% + 20px); /* Breite des Pseudo-Elements entspricht der Breite des Grids plus dem Abstand */
  height: calc(100% + 20px); /* Höhe des Pseudo-Elements entspricht der Höhe des Grids plus dem Abstand */
  margin: -10px; /* Negative Margin, um das Pseudo-Element über den grid-row-gap zu legen */
}

.horizontal4, .horizontal5{
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.vertical{
  display: grid;
  grid-row-gap: 5px;
  border-radius: 0 0 10px 10px;
}

.appWoche{
  display: grid;
  row-gap: 5px;
  grid-template-columns: repeat(1, 100%);
}

.appTag{
  display: grid;
  row-gap: 5px;
  grid-template-columns: repeat(1, 100%);
}

.labelHeaderCard{
  height: 30px !important;
  display: flex;
  padding: 0 20px 0 0 !important;
}

.special-date span{
  color: var(--secondary) !important;
  font-weight: bold;
}

.video-btn{
  min-width: 230px !important;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  margin: 0 0 10px 0;
  background-color: var(--secondary);
  color: white;
  text-decoration: none !important;
  border-radius: 10px;
}

.video-btn:hover{
  cursor: pointer;
}

.faqTitel strong{
  display: flex;
  min-width: 500px;
  width: fit-content;
}

/******************************/
/*API Klassen*/

.menuZusatzBild{
  width: 40px;
}

.homeLogo{
  color: var(--primary);
}
