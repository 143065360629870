.shadow-light{
  box-shadow: 0px 2px 4px 1.5px rgba(0, 0, 0, 0.25) !important;
}

.shadow {
  box-shadow: 0px 2px 4px 1.5px rgba(0, 0, 0, 0.25) !important;
}

.card-header{
  height: 35px;
  padding: 10px;
  background-color: #2980B9;
  color: white;
}

.card {
  width: calc(100% - 40px);
  margin: 10px;
  padding: 10px;
  background-color: #ECF0F1;
}

.card-white{
  margin: 0 0 10px 0;
  padding: 10px;
  background-color: #fff;
}

/**Button**/

.button-round {
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: var(--secondary);
  color: #fff;
  border-radius: 30px;
}

.button-round a{
  width: 100%;
  height: 100%;
  text-align: center;
}

.button-round i {
  margin: 0;
}

.button-good {
  background-color: var(--good);
}

.button-bad{
  background-color: var(--bad);
}

.button-info{
  background-color: var(--info);
}

.clear-float{
  clear: both;
}

/* Flex */

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-item {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}

.no-button-style{
  box-shadow: none;
}

/** Bestellelemente **/
.ordered{
  background-color: var(--good) !important;
  border-color: var(--good) !important;
  color: #fff;
}

.changed{
  background-color: var(--warning) !important;
  border-color: var(--warning) !important;
  color: #fff;
}
