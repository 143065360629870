/**###########################################################################################**/
/* Material - Checkboxen */

//.mdc-checkbox__background{
//  background-color: var(--primary) !important;
//  border-color: var(--primary) !important;
//}
//
//.mat-checkbox .mat-checkbox-frame {
//  border-color: black;
//  background-color: #dddddd
//}

.mdc-checkbox__background svg{
  color: white !important;
}

/**###########################################################################################**/
/* Material - Icon */

mat-icon{
  font-size: 25px !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  overflow: visible !important;
}

/**###########################################################################################**/
/* Material - Mat-Calendar */

mat-calendar-header button span{
  color: var(--schrift);
}

.mat-calendar-controls{
  height: 20px !important;
}

.mat-form-field-flex {
  margin: 5px;
  height: 45px;
  align-items: center !important;
}

.mat-form-field-infix{
  margin: 0 !important;
}

.mat-form-field-infix{
  margin: 0 4px 5px;
}

.mdc-icon-button{
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}

.mat-form-field-infix{
  width: 140px;
  margin: 7px 0 0 0;
}

.mat-form-field-underline {
  display: none;
}

.mat-calendar-body-cell{
  border: none !important;
  box-shadow: none !important;
}

.mat-calendar-body-cell-content{
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  line-height: 1;
  border-width: none;
  border-style: none;
  border-radius: 999px;
}

.mat-calendar-body-selected{
  border-width: 1px;
  border: 3px solid var(--secondary);
  background-color: var(--primary) !important;
  color: white !important;
}

.mat-calendar-body-today {
  border: 2px solid lightgray !important; /* Rahmen um heutiges Datum */
  background-color: #fff !important;
  color: #293133 !important;
}


.mat-calendar-body-disabled {
  color: grey;
}

.example-custom-date-class {
  background: var(--secondary) !important;
  color: white !important;
  border-radius: 100% !important;
}

.example-custom-date-class div:first-child{
  color: white !important;
}

.mat-icon-button.mat-button-disabled{
  margin: 0 2px;
  background-color: grey;
}

.mat-datepicker-content{
  border-radius: 10px !important;
}

/**###########################################################################################**/
/* Material - Mat-Dialog */

mat-dialog-container{
  width: 500px !important;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  box-shadow: none;
}

.mat-mdc-dialog-container .mdc-dialog__surface{
  box-shadow: none !important;
}

.mdc-dialog__container{
  max-width: 600px !important;
  height: fit-content;
}

.mdc-dialog__container .mat-mdc-dialog-surface{
  padding: 0 0 0 0;
}

#matDialogContent{
  padding: 10px;
}

#matDialogContentHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0 0 5px 0;

  color: var(--primary);
}

#matDialogContentHeader mat-icon{
  cursor: pointer;
}

#matDialogContentHeader mat-icon:hover{
  color: var(--secondary);
}

#matDialogContentData{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#matDialogContentFooter{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#matDialogContentData p{
  background-color: var(--primary) !important;
  color: #FFF;
  border-radius: 10px;
  padding: 10px;
}

.werbePopup mat-dialog-container{
  padding: 0;
  background-color: transparent;
}

.wba-container mat-dialog-container{
  /*background-color: #e9ecee;*/
  overflow: hidden !important;
  padding: 18px;
}

.menuVorauswahlEditor mat-dialog-container{
  width: 1000px !important;
}

.menuVorauswahlEditor .mdc-dialog__container{
  width: 100% !important;
  max-width: 100% !important;
}

.menuVorauswahlEditor .mat-mdc-dialog-surface{
  min-width: 100% !important;
}

.menuVorauswahlDialog mat-dialog-container{
  width: fit-content !important;
  min-width: 300px;
}

.mat-dialog-content{
  overflow: visible !important;
}

.expressStornoDialog-mobil mat-dialog-container{
  max-height: 600px;
}

.expressStornoDialog-mobil .mdc-dialog__surface{
  overflow: hidden !important;
}

/**###########################################################################################**/
/* Material - Mat-Chips */

#editorChipList .mdc-evolution-chip-set__chips{
  margin-left: 0 !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic{
  display:none !important;
}

#editorExpansionContent .mat-mdc-chip-selected{
  background-color: var(--secondary) !important;
  color: #fff !important;
}

#editorExpansionContent .mat-mdc-chip-selected .editorCardContentSpan{
  color: #fff !important;
}

#editorExpansionContent .mat-mdc-chip-selected mat-icon{
  color: #fff !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled){
  background-color: #fff !important;
  color: var(--schrift) !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .editorCardContentSpan{
  color: var(--schrift) !important;
}

/**###########################################################################################**/
/** Mat-Snackbar **/

.snackbar{
  color: #fff;
  border-radius: 10px !important;
}

.snackbar button{
  width: 100%;
  color: #fff;
  box-shadow: none;
  border: 1px #fff solid !important;
  border-radius: 10px;
}

snack-bar-container{
  background-color: var(--primary);
  padding: 5px !important;
  color: #fff;
  border-radius: 10px !important;
}

snack-bar-container button{
  background-color: transparent;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface{
  padding-right: 0 !important;
  border-radius: 10px !important;
  background-color: none !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  max-width: 100%;
  width: 100%;
}

.warenkorbSnackbar {
  max-width: 340px !important;
  width: 340px !important;
  position: absolute !important;
  top: 45px !important;
  left: 140px !important;
}

.warenkorbSnackbar .mdc-snackbar__label {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.mdc-snackbar{
  bottom: auto !important;
  right: auto !important;
}

.snackbar.goodMsg {
  --mdc-snackbar-container-color: var(--good);
}

.snackbar.goodMsg button{
  background: var(--good);
}

.snackbar.badMsg {
  --mdc-snackbar-container-color: var(--bad);
}

.snackbar.badMsg button{
  background: var(--bad);
}

.snackbar.neutralMsg {
  --mdc-snackbar-container-color: var(--info);
}

.snackbar.neutralMsg button{
  background: var(--info);
}

.snackbar-info button {
  background: white;
  color: #000;
}

/**###########################################################################################**/
/* Material - Mat-Bottom-Sheet */

mat-bottom-sheet-container{
  border-radius: 10px 10px 0 0 !important;
  border-top: 3px solid var(--primary) !important;
  border-left: 3px solid var(--primary) !important;
  border-right: 3px solid var(--primary) !important;
}

/**###########################################################################################**/
/* Material - Mat-Panel */

.mat-select-panel{
  min-width: 106% !important;
  max-width: 40% !important;
  border-radius: 10px !important;
  margin: 12% 0px -9px 1% !important;
  /* transform: translate(-2px, 44px) !important; */
}

.mat-expansion-panel-header {
  padding: 0 10px;
  height: 50px !important;
}

.mat-expansion-panel-body {
  padding: 0 10px 16px;
}

.filterHeader:hover{
  cursor: default !important;
}

.filterHeader .mat-expansion-panel-header-description{
  margin: 5px !important;
}

.expansionPanelWeb{
  margin: 2px 5px 5px 5px !important;
}

mat-expansion-panel{
  margin: 6px 5px 5px 5px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.speiseplanWba .mat-expansion-panel-body{
  padding: 0 !important;
}

.bestellhistorieExpansionPanel .mat-expansion-panel-body{
  padding: 0 !important;
  background-color: #E2E9F2;
}

.bestellhistorieExpansionPanel .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover{
  background: none !important;
}

/**###########################################################################################**/
/* Material - Mat-Card */

mat-card{
  width: calc(100% - 20px);
  background-color: #fff;
  margin: 5px;
  padding: 5px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.editorCard .mdc-evolution-chip__cell{
  justify-content: center;
}

.aizCard .mat-expansion-panel-body, .editorCard .mat-expansion-panel-body{
  padding: 0 !important;
}

/**###########################################################################################**/
/* Material - Mat-Slider */

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #2bcbba;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
  background-color: #2bcbba;
}

.mat-slide-toggle-bar, .mat-slide-toggle-thumb{
  background-color: #fc5c65;
}

/**###########################################################################################**/
/* Material - Mat-Table */

.mat-table{
  width: calc(100% - 20px);
  margin: 10px;
  border-radius: 4px;
}

.faq-table thead{
  display: none !important;
}

.faq-table tr.mat-row{
  display: block;
  height: fit-content !important;
}

.faq-table tbody{
  display: block;
}

.faq-table tr{
  background-color: #e9ecee;
}

#faqContainer .mdc-text-field--filled{
  background-color: #e9ecee;
  padding: 0 0 0 10px;
}

.rechtsTexteTable thead{
  display: none !important;
}

.rechtsTexteTable tr.mat-row{
  display: block;
  height: fit-content !important;
}

.rechtsTexteTable tbody{
  display: block;
}

.rechtsTexteTable mat-expansion-panel{
  box-shadow: none !important;
}

.rechtsTextePanel .mat-expansion-panel-body{
  border: 0.5px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0 0 0;
}

.mat-paginator{
  width: calc(100% - 20px);
  margin: 10px;
  background-color: var(--schrift) !important;
  border-radius: 4px;
}

#rechnungTabelle thead, #rechnungTabelle tbody{
  display: block;
}

/**###########################################################################################**/
/* Material - Sonstiges */

.cdk-overlay-connected-position-bounding-box {
  top:  55.5px !important;
}

.cdk-global-scrollblock{
  overflow: hidden !important;
  position: relative;
}

//.cdk-overlay-pane{
//  width: 100%;
//  display: flex;
//  justify-content: center;
//}

.mat-form-field-wrapper{
  padding: 0 !important;
}

.mat-slide-toggle-thumb{
  background-color: var(--primary) !important;
}

.mat-form-field-appearance-fill, .mat-form-field-flex{
  border-radius: 10px !important;
}

.mat-menu-item{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.mat-elevation-z8{
  box-shadow: none;
}

.mat-menu-content{
  background-color: #fff;
  padding: 10px;
}

.mat-datepicker-close-button{
  display: none;
}

.mat-divider{
  background-color: #205B9E;
}

.mat-select-min-line{
  color:black;
}

.mat-badge-content{
  width: 10px !important;
  height: 10px !important;
}

.messageContainer .mat-badge-active{
  color: var(--secondary) !important;
  background-color: var(--secondary) !important;
}

.labelHeaderCard .mat-content{
  justify-content: space-between;
}

.mat-mdc-chip-focus-overlay{
  background: transparent !important;
}
